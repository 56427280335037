import { useEffect, useRef, useState } from 'react'
import { Form, FormControl } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { generateInitName, validateUrl } from '../utils/logic';
import { createShortlink } from '../utils/api';
import { Box, Button, Card, Loader } from '@wix/design-system';
import { ERROR_KNOWN_THREAD, KNOWN_THREAD_WARNING } from '../utils/shared/constants';
import { ShortlinkType } from '../models/shared/Shortlink';

const RICK_ROLL = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ';

interface Inputs {
    name: string
    targetUrl: string
}

interface Props {
    showType: ShortlinkType | null
    handleClose: () => void
}

export default function NewShortlink(props: Props) {
    const nameRef = useRef<HTMLInputElement>(null);
    const [processing, setProcessing] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
        resetField
    } = useForm<Inputs>()

    useEffect(() => {
        if (props.showType) {
            resetField('name', { defaultValue: generateInitName() })

            if (nameRef.current) {
                nameRef.current.focus();
                nameRef.current.select();
            }
        }
    }, [props.showType, resetField])

    useEffect(() => {
        const Wix = (window as any).Wix;

        Wix.Dashboard.getSiteViewUrl((url: string) => {
            resetField('targetUrl', { defaultValue: url })
        });
    }, [resetField])

    if (!props.showType) {
        return null;
    }

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setProcessing(true)

        const newShortlinkData = {
            ...data,
            type: props.showType
        }
        try {
            await createShortlink(newShortlinkData);
            props.handleClose();
        } catch (e) {
            const message = (e as any).message || 'An error occurred';
            if (message.includes(ERROR_KNOWN_THREAD)) {
                alert(KNOWN_THREAD_WARNING);
            } else {
                console.log('error', e)
            }
        }
        setProcessing(false)
    }

    const { ref: nameFormRef, ...nameRegisterRest } = register('name')

    return (
        <Card>
            <Card.Header title={props.showType === ShortlinkType.DYNAMIC
                ? 'New QR Code'
                : 'New Static QR Code'}
                subtitle={props.showType === ShortlinkType.DYNAMIC
                    ? 'Create a dynamic QR Code that you can update anytime. Track performance with built-in analytics.'
                    : 'Static QR Codes offer a simple, fixed solution without analytics, and they’ll continue to work even if you no longer use the app.'
                }
            />
            <Card.Content>
                <form className='' onSubmit={handleSubmit(onSubmit)}>
                    <Box direction='vertical' gap="SP4" >
                        <div>

                            <div><label className='form-label mb-0'>Name</label></div>
                            <small className='text-muted'>Name is used for your own identification and is not shown externally</small>
                            <input ref={(e) => {
                                (nameRef as any).current = e;
                                nameFormRef(e);
                            }} className='form-control mt-1' type='text'  {...nameRegisterRest} />
                        </div>

                        <div>
                            <div><label className='form-label  mb-0'>Target URL</label></div>
                            <small className='text-muted'>Web address to create shortlink for. This can be anything from Wix page, Google search result or <a href={RICK_ROLL} target='_blank' rel='noreferrer'>this</a></small>
                            <FormControl isInvalid={!!errors.targetUrl} className='form-control mt-1' type='text' {...register("targetUrl", { validate: validateUrl })} placeholder={RICK_ROLL} />
                            <Form.Control.Feedback type="invalid">
                                {errors.targetUrl?.message}
                            </Form.Control.Feedback>
                        </div>
                        <Box direction='horizontal' gap={2} className=''>

                            <Button type='button' skin="inverted" onClick={() => props.handleClose()}>
                                Cancel
                            </Button>
                            <Button type='submit'>
                                {processing ? <Loader size='tiny' /> : "Create"}
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Card.Content>
        </Card>
    )
}
