export enum ShortlinkStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    DELETED = 'deleted',

}

export enum ShortlinkType {
    STATIC = 'static',
    DYNAMIC = 'dynamic',
}

export interface Shortlink {
    id: string
    alias?: string
    instanceId: string
    targetUrl: string
    name: string

    visited: number
    visitedInactive: number;

    lastVisitedAt?: Date
    createdAt: Date
    status: ShortlinkStatus
    type?: ShortlinkType
}
