import React, { useState } from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap';
import ProcessingButton from './ProcessingButton';
import { deleteShortlink } from '../utils/api';
import { Shortlink } from '../models/shared/Shortlink';

interface DeleteShortlinkModalProps {
    onHide: () => void;
    shortlink: Shortlink
}

const DeleteShortlinkModal: React.FC<DeleteShortlinkModalProps> = (props) => {
    const [processing, setProcessing] = useState(false);
    const [controlLinkName, setInputValue] = useState('');
    const { shortlink } = props;

    const isButtonDisabled = processing || controlLinkName !== shortlink.name;

    const handleDelete = async () => {
        setProcessing(true);
        await deleteShortlink(props.shortlink.id);
        setProcessing(false);
        props.onHide();
    };

    return (
        <Modal show={true} onHide={props.onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    Delete Shortlink: <strong>{shortlink.name}</strong>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {shortlink.alias && <p>{`Alias "${shortlink.alias}" will be released.`}</p>}
                <p>Are you sure you want to delete this QR code?</p>
                <p className="mb-2 text-muted">Confirm deletion by typing: <strong>{props.shortlink.name}</strong></p>
                <div className='input-wrapper'>
                    <FormControl
                        isInvalid={controlLinkName !== shortlink.name}
                        type="text"
                        value={controlLinkName}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder={props.shortlink.name}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-end">
                    <Button variant="outline-primary" onClick={props.onHide} disabled={processing} className="me-2">
                        Cancel
                    </Button>
                    <ProcessingButton
                        className='btn btn-danger'
                        onClick={handleDelete}
                        processing={processing}
                        disabled={isButtonDisabled}
                    >
                        Delete
                    </ProcessingButton>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export { DeleteShortlinkModal };
